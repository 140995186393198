import { FragmentType, getFragmentData } from '@/lib/gql';

import { AddressModelFragment } from './data-fragment';
export * from './data-fragment';

type Address = FragmentType<typeof AddressModelFragment>;

export namespace Address {
  // Function to find the IOCHAIN network address
  export function findIOCHAINAddress(addresses: Array<Address>): Address | undefined {
    return addresses.find((addr) => {
      const id = getFragmentData(AddressModelFragment, addr)?.id;
      if (!id) return false;
      return id.startsWith('IOCHAIN');
    });
  }

  // Function to get the default address or the IOCHAIN network address if applicable
  // If the user has chains-private permissions, default to the IOCHAIN network
  export function getDefaultAddress(
    vault: { addresses: Array<Address> },
    hasChainsPrivatePermissions: boolean,
  ): Address | undefined {
    const defaultAddress = vault.addresses.at(0);
    if (hasChainsPrivatePermissions) {
      return findIOCHAINAddress(vault.addresses) || defaultAddress;
    }
    return defaultAddress;
  }

  /**
   * @returns whether no addresses in the provided list belong to the given asset
   */
  export function noAddressesBelongsToAsset(addresses: Array<Address>, assetId: string): boolean {
    return addresses.every((addressParam) => {
      const addr = getFragmentData(AddressModelFragment, addressParam);
      if (!addr) return true;
      return addr.asset.id !== assetId;
    });
  }
}
